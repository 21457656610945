<template>

<div class="form" :class="{'is-decorated': block.decorate, 'is-errored': is.error}">

	<com-inner class="form-inner">

		<div class="form-inputs">

			<div class="form-inputs-text is-error" v-if="is.error">{{ block.form.feedback.error }}</div>
			<div class="form-inputs-text is-success" v-if="is.success">{{ block.form.feedback.success }}</div>

			<template v-if="!is.success">

				<template v-for="(input, index) in block.form.inputs">

					<com-textbox v-if="input.type === $constants.inputType.textbox" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-textarea v-if="input.type === $constants.inputType.textarea" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-select v-if="input.type === $constants.inputType.select" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-toggle v-if="input.type === $constants.inputType.toggle" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-checklist v-if="input.type === $constants.inputType.checklist" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-file v-if="input.type === $constants.inputType.file" v-model="model['field_' + input.id]" :error="errors['field_' + input.id]" :key="index" :input="input" />
					<com-title v-if="input.type === $constants.inputType.title" :key="index" :input="input" />

				</template>

				<div class="form-button" v-on:click="onSubmitClick" :class="{'is-loading': is.loading}"><span>{{ block.form.button }}</span><app-icon s="loading" v-if="is.loading" /></div>

			</template>

		</div>

		<com-text class="form-text" v-if="block.content" :block="block" />

	</com-inner>

</div>

</template>

<script>

import mixBlock from './mixin.js'

import Vue from 'vue'

export default {

	mixins: [mixBlock],

	components: {

		'com-text': () => import('./Text'),
		'com-textbox': () => import('./form/Textbox'),
		'com-textarea': () => import('./form/Textarea'),
		'com-select': () => import('./form/Select'),
		'com-toggle': () => import('./form/Toggle'),
		'com-checklist': () => import('./form/Checklist'),
		'com-file': () => import('./form/File'),
		'com-title': () => import('./form/Title'),

	},

	data: function() {

		return {
			is: {
				loading: false,
				success: false,
				error: false
			},
			errors: {},
			model: {}
		}

	},

	created: function() {

		this.$_.each(this.block.form.inputs, function(input) {

			Vue.set(this.model, 'field_' + input.id.toString(), (input.type === this.$constants.inputType.checklist) ? [] : '')

		}.bind(this))

	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.post('form', {
				context: this.$store.getters['context'].id,
				region: this.$store.getters['language'].id,
				block: this.block.id,
				form: this.block.form.id,
				data: this.model
			}).then(function(response) {

				if (response.redirect) {

					window.location.href = response.redirect

				} else {

					this.is.loading = false
					this.is.success = true
					this.is.error = false

				}

			}.bind(this), function(json) {

				this.is.loading = false
				this.is.error = true

				Vue.set(this, 'errors', {})

				this.$_.each(json.errors, function(key) {

					Vue.set(this.errors, key, true)

				}.bind(this))

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.is-desktop .form.is-decorated:after {
	content: '';
	position: absolute;
	left: calc(50% - 560px);
	bottom: 0px;
	width: 280px;
	height: 530px;
	pointer-events: none;
	background-size: contain;
	background-position: 50% 100%;
	background-repeat: no-repeat;
}

.is-aa .form.is-decorated:after {
	background-image: url(~@/assets/avatar.aa.png);
}

.is-afa .form.is-decorated:after {
	background-image: url(~@/assets/avatar.afa.png);
}

.is-stars .form.is-decorated:after {
	background-image: url(~@/assets/avatar.stars.png);
}

.form-inner {
	display: flex;
}

.is-device .form-inner {
	flex-direction: column;
	padding: 0px;
}

.is-desktop .form.is-decorated .form-inner {
	padding-left: 320px;
}

.form-inputs {
	flex-grow: 1;
}

.form-inputs-text {
	font-size: 16px;
	line-height: 22px;
	color: #343434;
	margin-bottom: 22px;
	color: #fff;
	padding: 20px;
	border-radius: 23px;
}

.form-inputs-text.is-error {
	background-color: #df5757;
}

.form-inputs-text.is-success {
	background-color: #42b145;
}

.form-text {
	flex-shrink: 0;
	width: 300px;
	margin-left: 40px;
}

.is-device .form-text {
	width: 100%;
	margin-left: 0px;
	margin-top: 20px;
}

.form-button {
	background-color: #1A234C;
	float: right;
	color: #fff;
	text-align: center;
	border-radius: 23px;
	height: 46px;
	line-height: 46px;
	padding: 0px 40px;
	text-transform: uppercase;
	cursor: pointer;
	letter-spacing: 1.2px;
	user-select: none;
	font-size: 12px;
}

.is-errored .form-button {
	background-color: #df5757;
}

.form-button >>> .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
}

.form-button.is-loading {
	pointer-events: none;
}

.form-button.is-loading span {
	visibility: hidden;
}

.form >>> .icon.error {
	position: absolute;
	left: -24px;
	top: 16px;
	color: #df5757;
}

</style>
